<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";

/**
 * Exports component
 */
export default {
  page: {
    title: "Exportações",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      title: "Exportações",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Exportações",
          active: true,
        },
      ],
      reportTypes: [
        {
          key: 1,
          value: "Relatório de Cursos"
        },
        {
          key: 2,
          value: "Relatório Detalhado de Cursos"
        },
        {
          key: 3,
          value: "Relatório de Grupos"
        },
        {
          key: 4,
          value: "Relatório Detalhado de Grupos"
        },
        {
          key:5,
          value: "Relatório de Gameficação"
        },
      ],
      courses: [
        {
          key: 1,
          value: "Curso 1"
        },
        {
          key: 2,
          value: "Curso 2"
        },
      ],
      groups: [
        {
          key: 1,
          value: "Grupo 1"
        },
        {
          key: 2,
          value: "Grupo 2"
        },
      ],
      form: {
        type: "",
        course: "",
        group: "",
        startDate: "",
        finalDate: "",
      },
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  methods: {
    formSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :enableCad="false" />
    <div class="row justify-content-center">      
      <div class="col-xl-8">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Exportar Relatório</h4>
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label" for="validationCustom01">Escolha o Relatório para Exportar</label>
                    <multiselect  
                      v-model="form.type"
                      :options="reportTypes" 
                      placeholder="Selecione o tipo de relatório"
                      label="value" 
                      name="type"
                      track-by="key"
                    ></multiselect>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label" for="validationCustom01">Cursos (*Apenas Relatório do Tipo "Relatório Detalhado de Curso")</label>
                    <multiselect  
                      :options="courses" 
                      v-model="form.course"
                      placeholder="Selecione o curso"
                      label="value" 
                      name="type"
                      track-by="key"
                    >
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label" for="validationCustom01">Grupos (*Apenas Relatório do Tipo "Relatório Detalhado de Grupo")</label>
                    <multiselect  
                      :options="groups" 
                      v-model="form.group"
                      placeholder="Selecione o grupo"
                      label="value" 
                      name="type"
                      track-by="key"
                    ></multiselect>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                  <div class="col">
                      <label for="dateStart">Data inicial</label>
                      <input type="date" v-model="form.startDate" class="form-control" id="dateStart" name="dateStart" placeholder="00/00/0000" required="">
                  </div>
                  <div class="col">
                      <label for="dateEnd">Data final</label>
                      <input type="date" v-model="form.finalDate" class="form-control" id="dateEnd" name="dateEnd" placeholder="00/00/0000" required="">
                  </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-3">
                  <button class="btn btn-primary w-100" type="submit">Filtrar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
